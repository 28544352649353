<template>
  <div class="card p-shadow-6">
    <div class="p-col-12 p-lg-12">
      <h3>Propostas | Mensagens Não Lidas</h3>
      <div style="height: 25vh">
        <DataTable
          :value="messageList"
          :loading="loading"
          class="p-datatable-sm"
          dataKey="id"
          responsiveLayout="scroll"
          :scrollable="true"
          scrollHeight="flex"
          removableSort
        >
          <template #empty> Nada a mostrar. </template>
          <template #loading> A carregar. Por favor Aguarde... </template>
          <Column
            field="message_proposal.id"
            header="Proposta"
            :headerStyle="{ 'min-width': '6%', 'max-width': '6%' }"
            :filterHeaderStyle="{ 'min-width': '6%', 'max-width': '6%' }"
            :bodyStyle="{ 'min-width': '6%', 'max-width': '6%' }"
          >
            <template #body="slotProps">
              <span
                v-if="slotProps.data.message_proposal != null"
                class="clickable-item"
                v-tooltip="'Ver Proposta'"
                @click="viewProposal(slotProps.data)"
              >
                {{ `P${slotProps.data.message_proposal.id}` }}
              </span>
            </template>
          </Column>
          <Column
            field="email_from"
            header="De"
            :headerStyle="{ 'min-width': '20%', 'max-width': '20%' }"
            :filterHeaderStyle="{ 'min-width': '20%', 'max-width': '20%' }"
            :bodyStyle="{ 'min-width': '20%', 'max-width': '20%' }"
          />
          <Column
            field="subject"
            header="Assunto"
            :headerStyle="{ 'min-width': '50%', 'max-width': '50%' }"
            :filterHeaderStyle="{ 'min-width': '50%', 'max-width': '50%' }"
            :bodyStyle="{ 'min-width': '50%', 'max-width': '50%' }"
          >
            <template #body="slotProps">
              <span
                class="clickable-item"
                v-tooltip="'Ver Email'"
                @click="viewMessage(slotProps.data)"
              >
                {{ `P${slotProps.data.subject}` }}
              </span>
            </template>
          </Column>
          <Column
            field="created_on"
            header="Data"
            :headerStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :filterHeaderStyle="{ 'min-width': '15%', 'max-width': '15%' }"
            :bodyStyle="{ 'min-width': '15%', 'max-width': '15%' }"
          >
            <template #body="slotProps">
              {{ formatTime(slotProps.data.created_on) }}
            </template>
          </Column>
          <Column
            field="message_status.name"
            header=""
            :headerStyle="{ 'min-width': '9%', 'max-width': '9%' }"
            :filterHeaderStyle="{ 'min-width': '9%', 'max-width': '9%' }"
            :bodyStyle="{ 'min-width': '9%', 'max-width': '9%' }"
          >
            <template #body="slotProps">
              <span
                class="clickable-item"
                v-tooltip="
                  slotProps.data.message_status.status == 1
                    ? 'Marcar como Lida'
                    : slotProps.data.message_status.status == 2
                    ? 'Marcar como não Lido'
                    : ''
                "
                @click="toogleStatus(slotProps.data)"
              >
                {{ `${slotProps.data.message_status.name}` }}
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
      <Paginator
        v-if="messagesInfo"
        :rows="messagesInfo.per_page"
        :totalRecords="messagesInfo.total"
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
        :currentPageReportTemplate="'{currentPage} de {totalPages}'"
        @page="changePage"
      ></Paginator>

      <div class="p-col-12" v-if="viewMessageInfo && viewMessageInfo.html">
        <divider />
        <div>
          <b>De:</b> {{ viewMessageInfo.email_from }} |
          {{ viewMessageInfo.received_on }}
        </div>
        <div class="p-mt-1"><b>Para:</b> {{ viewMessageInfo.email_to }}</div>
        <div class="p-mt-1" v-if="viewMessageInfo.email_cc != ''">
          <b>CC:</b> {{ viewMessageInfo.email_cc }}
        </div>
        <div class="p-mt-1"><b>Assunto:</b> {{ viewMessageInfo.subject }}</div>
        <div v-if="viewMessageInfo.message_attachments.length > 0">
          <divider />
          <i class="pi pi-paperclip"></i><b>Anexos:</b>
          <div>
            <Button
              class="p-button-sm p-button-rounded p-ml-1 p-mt-1"
              v-for="attachment in viewMessageInfo.message_attachments"
              :key="attachment.id"
              :label="attachment.name"
              icon="pi pi-file"
              @click="downloadAttachments(viewMessageInfo.id, attachment.id)"
            />
          </div>
        </div>
        <divider />
        <div v-html="viewMessageInfo.html" style="overflow-x: auto"></div>
      </div>
      <div
        v-if="loadingPreview"
        class="p-col-12"
        :style="{ position: 'relative', height: '20vh' }"
      >
        <Loading :active="true" :isFullPage="false"></Loading>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import proposalEmailsService from "../services/proposalEmails.service";
import paginatorService from "../services/paginator.service";
import moment from "moment";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "ProposalMessagesUnread",
  components: { Loading },
  data() {
    return {
      loading: true,
      loadingPreview: false,
      messageList: [],
      showMessage: false,
      messagesInfo: null,
      lastPage: 0,
      viewMessageInfo: null,
    };
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
  },
  async created() {
    if (
      this.can("seeUnreadEmailsProposals") ||
      this.$store.state.auth.user.isEsa
    ) {
      this.messagesInfo = await this.getMessages();
      this.messageList = this.messagesInfo.data;
    }
    this.loading = false;
  },
  methods: {
    formatTime(dateTime) {
      return moment(dateTime).format("DD-MM-YYYY HH:mm");
    },
    getMessages() {
      return proposalEmailsService.getByStatus(1);
    },
    changePage(event) {
      this.loading = true;
      this.viewMessageInfo = null;
      return paginatorService
        .getItems(this.messagesInfo.links[event.page + 1].url)
        .then((resp) => {
          this.messageList = resp.data;
          return (this.loading = false);
        });
    },
    setMessageStatus(email, status) {
      return proposalEmailsService
        .setStatus(email.id, { status_id: status })
        .then((response) => {
          email.status = response.status_id;
          email.message_status.status = response.status_id;
          email.message_status.name = response.status_name;
        });
    },
    async viewProposal(email) {
      await this.setMessageStatus(email, 2);
      let route = this.$router.resolve({
        path: `/proposal-detail/${email.message_proposal.id}`,
      });
      window.open(route.href, "_blank");
    },
    async viewMessage(email) {
      this.loadingPreview = true;
      this.viewMessageInfo = null;
      await this.setMessageStatus(email, 2);

      this.viewMessageInfo = await proposalEmailsService.getMessage(email.id);
      this.processedHtml();
      return (this.loadingPreview = false);
    },
    processedHtml() {
      let el = new DOMParser().parseFromString(
        this.viewMessageInfo.body_html,
        "text/html"
      );
      [...this.viewMessageInfo.message_attachments_inline].forEach(
        (inlineImg) => {
          let image;
          if (inlineImg.content_id) {
            image = el.querySelector(`img[src$='${inlineImg.content_id}' i]`);
          } else {
            image = el.querySelector(`img[alt$='${inlineImg.name}']`);
          }
          if (!image) {
            this.viewMessageInfo.message_attachments.push({
              id: inlineImg.id,
              name: inlineImg.name,
            });
          } else {
            image.src = `data:${inlineImg.mimetype};${inlineImg.encoding},${inlineImg.data}`;
          }
        }
      );
      return (this.viewMessageInfo.html = el.documentElement.innerHTML);
    },
    downloadAttachments(emailId, attachmentId) {
      return proposalEmailsService
        .getAttachment(emailId, attachmentId)
        .then((response) => {
          let linkSource = `data:${response.mimetype};base64,${response.data}`;
          let downloadLink = document.createElement("a");
          let fileName = response.name;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        });
    },
    async toogleStatus(email) {
      if (email.message_status.status == 1) {
        return await this.setMessageStatus(email, 2);
      } else if (email.message_status.status == 2) {
        return await this.setMessageStatus(email, 1);
      }
      return;
    },
  },
};
</script>
